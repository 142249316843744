<template>
  <base-layout-two :page-title="user.fullname" :page-default-back-link="`/users/${$route.params.id}/activities`"
    end-button-text="Edit" :end-button-url="`/users/${user.id}/activities/${activity.id}/edit`">
    <section class="ion-padding background-white">
      <h1>
        {{ activityTitle }}
        <span class="ion-text-capitalize" v-if="activity.measurement_1">
          {{ activity.measurement_1 ? `(${activity.measurement_1}${activity.units_1} ${activity.option_1})` : `` }}
        </span>

        <span class="danger" v-if="!activity.measurement_1 && showMeasurement1">
          (missing amount)
        </span>
      </h1>
    </section>

    <div class="padding-8">
      <base-card title="Activity Details">
        <base-field-display label-text="Time & Date">
          <p>{{ formatTimeAndDate(activity.datetime) }}</p>
        </base-field-display>

        <base-field-display label-text="Formula or Breastmilk" v-if="showOption1">
          <p class="ion-text-capitalize">{{ activity.option_1 }}</p>
        </base-field-display>

        <base-field-display label-text="Amount" v-if="showMeasurement1">
          <p v-if="activity.measurement_1">{{ activity.measurement_1 }} {{ activity.units_1 }}</p>
          <p v-if="!activity.measurement_1" class="danger">Missing Amount</p>
        </base-field-display>

        <base-field-display label-text="Scanned">
          <p class="ion-text-capitalize">{{ (activity.scanned) ? 'Yes' : 'No' }}</p>
        </base-field-display>

        <base-field-display label-text="Notes">
          <p>{{ activity.notes }}</p>
        </base-field-display>
      </base-card>
    </div>
  </base-layout-two>
</template>

<script>
import { format } from "date-fns";
import { formatTimeAndDate } from "@/util/helpers";

export default {
  components: {
  },

  data() {
    return {
      user: {},
      activity: {
        datetime: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
      },
      formatTimeAndDate
    };
  },

  computed: {
    activityTitle() {
      if (this.activity.type === "sitz_bath")
        return "Sitz Bath";
      else if (this.activity.type === "foot_bath")
        return "Foot Bath";
      else if (this.activity.type === "breast_massage")
        return "Breast Massage";
      else if (this.activity.type === "lactation_assistance")
        return "Lactation Assistance";
      else if (this.activity.type === "pump_delivered")
        return "Delivered Breast Pump";
      else if (this.activity.type === "pump_retrieved")
        return "Retreived Breast Pump";
      else if (this.activity.type === "baby_in_guest_room")
        return "Baby in Room";
      else if (this.activity.type === "baby_in_nursery")
        return "Baby in Nursery";
      else if (this.activity.type === "baby_feeding")
        return "Baby Feeding";
      else if (this.activity.type === "bottle_prep")
        return "Bottle Prep";
      else if (this.activity.type === "breastmilk_received")
        return "Collected Breastmilk";
      else if (this.activity.type === "baby_bath")
        return "Bath";
      else if (this.activity.type === "baby_diaper_wet")
        return "Diaper (Wet)";
      else if (this.activity.type === "baby_diaper_poop")
        return "Diaper (Poop)";
      else if (this.activity.type === "baby_diaper_empty")
        return "Diaper (Empty)";
      else if (this.activity.type === "baby_diaper_mixed")
        return "Diaper (Mixed)";
      else if (this.activity.type === "sales_info_session_zoom")
        return "Info Session (Zoom)";
      else if (this.activity.type === "sales_ca_call_zoom")
        return "1:1 CA Call (Zoom or Phone)";
      else if (this.activity.type === "sales_tour_onsite")
        return "Tour (Onsite)";
      else if (this.activity.type === "note")
        return "Team Note";
      else return "Activity";
    },

    showOption1() {
      if (this.activity.type === "baby_feeding") return true;
      return false;
    },

    showMeasurement1() {
      if (this.activity.type === "baby_feeding" || this.activity.type === "breastmilk_received")
        return true;
      return false;
    },

    showUnits1() {
      if (this.activity.type === "baby_feeding" || this.activity.type === "breastmilk_received")
        return true;
      return false;
    },
  },

  ionViewWillEnter() {
    this.fetchUser();
    this.fetchActivity();
  },

  methods: {
    /**
     * Fetch Activity
     */
    async fetchActivity() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/activities/${this.$route.params.activity_id}`)
        .then((response) => {
          this.activity = response.data.activity;
        })
        .catch((error) => { console.log(error) });
    },

    /**
     * Fetch User
     */
    async fetchUser() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/users/${this.$route.params.id}`)
        .then((response) => {
          this.user = response.data.user;
        })
        .catch((error) => { console.log(error) });
    },
  },
};
</script>